<template>
  <div class="fiscal-year">
    <Banner>
      <div class="banner-content">
        <MeepIconSettings class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.setting") }}</span>
      </div>
    </Banner>
    <div class="fiscal-year-cards page-layout">
      <SettingCard
        :title="$t('fiscal-year.add.title')"
        @onClick="handleClickAdd"
        :disable="disableAction"
      />
      <UnlockFiscalYear v-model="isLoading" :disable="disableAction" />
      <DeleteFiscalYear v-model="isLoading" :disable="disableAction" />
    </div>
    <confirm-modal
      class="modal-fiscal-year"
      v-model="isConfirmModalOpen"
      :title="$t('fiscal-year.modal.title')"
      :description="$t('fiscal-year.modal.description')"
      :question="$t('fiscal-year.modal.question')"
      :cta-yes="$t('common.yes')"
      :cta-no="$t('common.no')"
      @close="isConfirmModalOpen = false"
      @confirm="handleCreateFiscalYear"
    />
    <LoadingModal v-model="isLoading" :text="$t('modal.text-loading')" />
    <AnnounceModal
      class="announce-fiscal-modal"
      v-model="isAnnounceModalOpen"
      :isNotiSuccess="isSuccess"
      :description="$t('simulateurs.credit.modal-description')"
      @close="isAnnounceModalOpen = false"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Banner from "@/components/Banner.vue";
import MeepIconSettings from "@/components/icons/MeepIconSettings.vue";
import SettingCard from "@/components/SettingCard.vue";
import ConfirmModal from "@/components/modal/ConfirmModal.vue";
import AnnounceModal from "@/components/modal/AnnounceModal.vue";
import LoadingModal from "@/components/modal/LoadingModal.vue";
import UnlockFiscalYear from "./UnlockFiscalYear.vue";
import DeleteFiscalYear from "./DeleteFiscalYear.vue";
import filesModel from "../../../model/files";

export default {
  name: "Setting",
  computed: {
    ...mapGetters([
      "isClient",
      "isJEPA",
      "isAdmin",
      "isEmployee",
      "organizationId",
    ]),
  },
  props: [],
  components: {
    Banner,
    MeepIconSettings,
    SettingCard,
    ConfirmModal,
    AnnounceModal,
    LoadingModal,
    UnlockFiscalYear,
    DeleteFiscalYear,
  },
  data() {
    return {
      isConfirmModalOpen: false,
      isAnnounceModalOpen: false,
      isLoading: false,
      isSuccess: true,
      disableAction: false,
      timeout: null,
    };
  },
  async mounted() {
    await this.checkCurrentProcesses();
  },
  beforeDestroy() {
    this.clearTimeoutCheckProcess();
  },
  methods: {
    handleClickAdd() {
      this.isConfirmModalOpen = true;
    },
    async handleCreateFiscalYear() {
      this.isConfirmModalOpen = false;
      this.isLoading = true;
      this.disableAction = true;
      try {
        await filesModel.createFiscalYear(
          this.organizationId,
          new Date().getFullYear().toString()
        );

        await this.checkCurrentProcesses();
      } catch (err) {
        this.isSuccess = false;
        this.isLoading = false;
        this.disableAction = false;
        this.isAnnounceModalOpen = true;
      }
    },
    async checkCurrentProcesses() {
      let _processes = await filesModel.getAllCurrentProcesses();
      while (Object.keys(_processes).length) {
        this.timeout = setTimeout(async () => {
          _processes = await filesModel.getAllCurrentProcesses();
          let isDone = true;
          if (Object.keys(_processes).length) {
            this.isLoading = true;
            this.disableAction = true;
          }
          Object.keys(_processes).forEach((key) => {
            if (_processes[key].status !== "done") {
              isDone = false;
            }
          });
          if (isDone && Object.keys(_processes).length) {
            this.isSuccess = true;
            this.isLoading = false;
            this.disableAction = false;
            this.isAnnounceModalOpen = true;
          }
        }, 1000);
        if (!this.isLoading) {
          this.clearTimeoutCheckProcess();
        }
      }
    },
    clearTimeoutCheckProcess() {
      clearTimeout(this.timeout);
    },
  },
};
</script>
<style lang="scss">
@import "../../../styles/_variable.scss";
.fiscal-year {
  &-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 24px;
  }

  .setting-card {
    .setting-card-container .v-card .md-button {
      .md-ripple {
        font-size: toRem(14);
      }
    }
  }
  .app-icon--fill-white {
    path {
      fill: white;
    }
  }
}
.modal-fiscal-year {
  .confirm-modal-buttons {
    display: flex;
    align-items: center;
  }
}
.announce-fiscal-modal {
  .announce-modal-body-context {
    color: #032575;
    font-weight: 500;
    font-size: toRem(14);
    @include for-lg {
      font-size: toRem(22);
    }
  }
}
</style>
